.page{
    background-color: #F2F2F7;
    padding: 24px 0;

    font-family: 'Museo Sans Cyrl';
    color: #373A42;
}

.section{
    background: #FFFFFF;
    border-radius: 32px;

    max-width: 1092px;
    margin: 40px auto;
    padding: 65px 0;
}

.section__wrap{
    max-width: 632px;
    margin: 0 auto;

    position: relative;
}


@media screen and (max-width: 1100px){
    .page{
        padding: 4px 0;
    }
    .section{
        margin: 32px auto;
    }
    .section__wrap{
        padding: 0 16px;
        max-width: 664px;
    }
}


.logo_title{
    margin: 0 auto;
    display: block;
}

.link_back{
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    background: #EDF1F5 url("../icons/ic_arrow-left.svg") no-repeat center / auto;
    border-radius: 8px;
}

.wrap{
    max-width: 1092px;
    margin: 0 auto;
}

@media screen and (max-width: 1100px){
    .wrap{
        padding: 0 16px;
    }
}

.card{
    border-radius: 32px;
    position: relative;
    padding: 40px;
    color: #373A42;
    margin-bottom: 32px;
}
.card:last-child{
    margin-bottom: 0;
}

.card__title{
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;

    text-transform: uppercase;
}

.card__p{
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;

    letter-spacing: 1px;
    margin: 24px 0;
}

.card__p:last-child{
    margin-bottom: 0;
}

.card__img{
    position: absolute;
}

.card__content{
    position: relative;
    z-index: 1;
}

.card_rediscover{
    overflow: visible;
    padding-right: 400px;
    background: linear-gradient(180deg, rgba(226, 113, 167, 0.3) 0%, rgba(226, 113, 167, 0) 100%), #FFFFFF;
}

.card_rediscover .card__content{
    max-width: 656px;
}
.card_rediscover .card__img{
    top: -48px;
    right: -25px;
}

.card_tap{
    padding-top: 115px;
    padding-right: 396px;

    background: #B7E271;
    overflow: hidden;
}

.card_tap .card__img{
    width: 718px;
    top: -70px;
    right: 30px;
}

.card_collab{
    padding-left: 546px;
    background: #FFFFFF;
}

.card_collab .card__img{
    top: -12px;
    left: 48px;
}

.card_watch{
    display: flex;
    align-items: center;
    min-height: 231px;
    padding-left: 546px;
    background: linear-gradient(180deg, rgba(87, 193, 255, 0.24) 0%, rgba(87, 193, 255, 0) 100%), #FFFFFF;
}

.card_watch .card__img{
    top: -33px;
    left: 74px;
}

.card_channels{
    background: #E271A7;
    color: #FFFFFF;
    padding-top: 147px;
    overflow: hidden;
}
.card_channels .card__img{
    top: 50px;
    left: -30px;
}

.card_theme{
    background: #E89142;
    color: #fff;
    display: flex;
    align-items: center;
    min-height: 231px;
}

.card_theme .card__content{
    max-width: 506px;
}

.card_theme .card__img{
    width: 244px;
    height: 225px;
    top: -28px;
    right: 168px;
}

.card_react{
    color: #fff;
    padding-top: 90px;
    padding-left: 503px;
    overflow: hidden;
}

.card_react:before{
    content: '';
    width: 100%;
    height: 100%;
    background: url("../images/img_react_bg.png") no-repeat center / 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.card_react .card__title{
    color: #F6E823
}

.card_react .card__img{
    left: 70px;
    top: 0;
    width: 343px;
    height: 340px;
}

.card_supercut{
    background: #D2E780;
    padding-top: 165px;
    overflow: hidden;
    color: #D2E780;
}

.card_supercut .card__content{
    max-width: 656px;
}

.card_supercut .card__img{
    top: 0;
    left: 0;
}

.card_search{
    background: #E27871;
    padding-top: 64px;
    overflow: hidden;
    color: #fff;
}

.card_search .card__content{
    max-width: 656px;
}

.card_search .card__img{
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
}

.card_hide{
    background: #B7E271;
    padding-left: 546px;
    padding-top: 65px;
}

.card_hide .card__img{
    top: 0;
    left: 0;
}

.card_export{
    background: #E89142;
    color: #fff;
    padding-top: 64px;
    overflow: hidden;
}
.card_export .card__content{
    max-width: 506px;
}

.card_export .card__img{
    top: 0;
    right: 97px;
    width: 368px;
    height: 263px;
}


.card_save{
    background: linear-gradient(180deg, rgba(87, 193, 255, 0.61) 13.1%, rgba(87, 193, 255, 0) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
    overflow: hidden;
}

.card_save .card__img{
    bottom: 0;
    left: 0;
    width: 100%;
}



@media screen and (max-width: 768px) {

    .link_back{
        left: 5px;
    }

    .card{
        padding: 32px 16px;
        overflow: hidden;
    }

    .card__p{
        font-size: 16px;
    }

    .card_rediscover .card__img{
        width: 310px;
        height: 289px;

        top: -25px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }

    .card_rediscover{
        padding-top: 280px;
        overflow: visible;
    }

    .card_tap .card__img{
        width: 343px;
        height: auto;
        right: 0;
        top: 0;
    }

    .card_tap{
        padding-top: 167px;
    }

    .card_collab{
        padding-top: 212px;
    }

    .card_collab .card__img{
        width: 279px;
        top: 32px;
        left: 50%;
        transform: translateX(-50%);
    }

    .card_watch{
        padding-top: 208px;
        overflow: visible;
    }

    .card_watch .card__img{
        height: 206px;
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
    }

    .card_channels{
        padding-top: 124px;
    }

    .card_channels .card__img{
        left: 50%;
        transform: translateX(-55%);
    }

    .card_theme{
        padding-top: 216px;
        overflow: visible;
    }

    .card_theme .card__img{
        top: -22px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }

    .card_react:before{
        /*transform: rotate(90deg);*/
        background-size: cover;
        background-position: top left;
    }

    .card_react .card__img{
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
        height: auto;
    }
    .card_react{
        padding-top: 294px;
    }

    .card_supercut{
        padding-top: 172px;
    }

    .card_supercut .card__img{
        top: 0;
        left: 0;
        height: 100%;
    }
    .card_search .card__img{
        top: 0;
        left: 50%;
        transform: translateX(-41%);
    }

    .card_hide{
        padding-top: 192px;
    }

    .card_hide .card__img{
        top: 11px;
        height: 190px;
    }

    .card_export{
        padding-top: 195px;
    }

    .card_export .card__img{
        top: -28px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        width: 260px;
    }
    .card_save .card__img{
        bottom: auto;
        top: 0;
        height: 340px;
        width: auto;
    }

}
.store__link{
    display: block;
    background-color: rgba(216, 225, 234, 0.45);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 33px;
    border-radius: 10px;
    margin-right: 16px;
    width: 193px;
    height: 56px;

}

.store__link:last-child{
    margin-right: 0;
}

.store__link_appstore{
    background-image: url("../images/link_appstore.svg");
}
.store__link_googleplay{
    background-image: url("../images/link_googleplay.svg");
}

@media screen and (min-width: 768px){
    .store__lnk_small{
        width: 113px;
        height: 32px;
        background-size: auto;
    }
}



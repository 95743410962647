.title{
    font-weight: 600;
    font-size: 40px;
    line-height: 36px;
    text-transform: uppercase;
    color: #373A42;
    font-family: 'Barlow Condensed', sans-serif;
}
/* стили из main END */

/* COMPONENT button START */
.button{
    width: 100%;
    height: 56px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 8px;
    outline: none;

    cursor: pointer;
    transition: 200ms;
}
.button:hover{
    background: #E5EFFA;
}
.button:active{
    background: #B8D4F0;
}
.button_gradient{
    background: linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.44);
}
.button_gradient:hover{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.64);
}
.button_gradient:active{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.54);;
}
.button__text{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 1.3px;
    color: #007AFF;
    background: #EDF1F5;
    font-family: 'Roboto', sans-serif;
}





.button__text_white{
    color: #FFFFFF
}
.button__icon{
    width: 25px;
    height: 25px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    margin-right: 10px;
    margin-top: -4px;
    margin-left: 5px;
}
.button__icon_auth{
    background-image: url("../icons/ic_auth.svg");
}
.button_err:hover,
.button_err{
    background-color: #FF3B30;
}
.button_err .button__text{
    color: #fff;
}

/* COMPONENT button END */

/* COMPONENT Drop START */
.drop_area{
    height: 298px;

    background: rgba(237, 241, 245, 0.5);
    border: 1px dashed #007AFF;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}

@media screen and (max-width: 1000px){
    .drop_area{
        height: auto;
        background: transparent;
        border: none;
    }
}









.drop_area_inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F2F2F7;
}


@media screen and (max-width: 1000px){
    .drop_area_inner{
        background: transparent;
    }
}




.drop_area__button{
    width: 100%;
    max-width: 183px;
}


@media screen and (max-width: 1000px){
    .drop_area__button{
        max-width: 100%;
    }
}


.drop__button{
    margin-top: 40px;
    width: 100%;

}




@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
.drop_area__title{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #373A42;
}


@media screen and (max-width: 1000px){
    .drop_area__title{
        display: none;
    }
}



.drop_area__or{
    text-transform: none;
    margin: 16px 0 8px;
}

@media screen and (max-width: 1000px){
    .drop_area__or{
        display: block;
        margin-top: 0;
    }
}

/* COMPONENT Drop END */

/* стили только к Import Page START */
.drop{
    padding-right: 60px;
    position: relative;
}

@media screen and (max-width: 1000px){
    .drop{
        padding-right: 0px;
        margin-bottom: 30px;
    }

    .drop:after{
        display: none;
    }
}



.drop:after{
    content: '';
    width: 1px;
    height: 470px;
    background-color: rgba(54, 54, 54, 0.2);

    position: absolute;
    top: 0px;
    right: 0;
}

.drop .drop_area{
    margin-top: 40px;
}


@media screen and (max-width: 1000px){
    .drop .drop_area{
        margin-top: 10px;
    }
}


/* стили только к Import Page END */
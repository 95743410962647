/* стили из main START */
.page {
    background-color: #F2F2F7;
    padding: 24px 0;

    font-family: 'Museo Sans Cyrl';
    color: #373A42;
}

.wrap {
    max-width: 1092px;
    margin: 0 auto;
}

@media screen and (max-width: 1100px) {
    .wrap {
        padding: 0 16px;
    }
}

.section {

    background: #FFFFFF;
    border-radius: 32px;
    max-width: 1092px;
    margin: 40px auto;
    padding: 65px 0;
    font-family: 'Roboto', sans-serif;
}
.section__wrap {
    max-width: 965px;
    margin: 0 auto;
}
.section__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 1000px){
    .section__headline{
        padding: 0 15px;
    }
}



.title {
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    color: #373A42;
}
.title_center {
    text-align: center;
}
.section__title {
    margin-bottom: 44px;
}
.section__columns {
    display: grid;
    grid-template-columns: 460px auto;
}

@media screen and (max-width: 1000px) {
    .section__columns {
        grid-template-columns: 1fr;
        padding: 0 15px;
    }
}
.section__p {
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    letter-spacing: 1px;
    color: #373A42;
    margin: 20px 0;
}
/* стили из main END */
/* стили только к Import Page START */
.section__headline {
    margin-bottom: 64px;
}
.link_exit {
    display: block;
    width: 30px;
    height: 28px;

    background: url("data:image/svg+xml,%3Csvg width='31' height='28' viewBox='0 0 31 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.73698 28C3.38748 28 2.37318 27.6604 1.69407 26.9813C1.02368 26.3022 0.688477 25.2792 0.688477 23.9123V4.10075C0.688477 2.74254 1.02368 1.71953 1.69407 1.03172C2.37318 0.343905 3.38748 0 4.73698 0H18.6064C19.9559 0 20.9658 0.343905 21.6362 1.03172C22.3153 1.71953 22.6549 2.74254 22.6549 4.10075V10.1474H20.5523V4.13993C20.5523 3.48694 20.3781 2.98632 20.0299 2.63806C19.6903 2.28109 19.181 2.10261 18.5019 2.10261H4.84146C4.16236 2.10261 3.64867 2.28109 3.30042 2.63806C2.96086 2.98632 2.79109 3.48694 2.79109 4.13993V23.8731C2.79109 24.5261 2.96086 25.0267 3.30042 25.375C3.64867 25.7233 4.16236 25.8974 4.84146 25.8974H18.5019C19.181 25.8974 19.6903 25.7233 20.0299 25.375C20.3781 25.0267 20.5523 24.5261 20.5523 23.8731V17.8526H22.6549V23.9123C22.6549 25.2792 22.3153 26.3022 21.6362 26.9813C20.9658 27.6604 19.9559 28 18.6064 28H4.73698ZM12.7687 15.0317C12.4988 15.0317 12.2637 14.9316 12.0635 14.7313C11.8632 14.5224 11.7631 14.2786 11.7631 14C11.7631 13.7214 11.8632 13.4776 12.0635 13.2687C12.2637 13.0597 12.4988 12.9552 12.7687 12.9552H26.181L28.14 13.0466L27.2127 12.1586L25.1101 10.1735C24.9012 9.99067 24.7967 9.75124 24.7967 9.45522C24.7967 9.18532 24.8794 8.96331 25.0448 8.78918C25.2189 8.60634 25.441 8.51493 25.7109 8.51493C25.9633 8.51493 26.1941 8.6194 26.403 8.82836L30.6605 13.2425C30.7911 13.3731 30.8781 13.4994 30.9217 13.6213C30.9739 13.7432 31 13.8694 31 14C31 14.1306 30.9739 14.2568 30.9217 14.3787C30.8781 14.5006 30.7911 14.6269 30.6605 14.7575L26.403 19.1716C26.1941 19.3719 25.9633 19.472 25.7109 19.472C25.441 19.472 25.2189 19.3806 25.0448 19.1978C24.8794 19.0149 24.7967 18.7886 24.7967 18.5187C24.7967 18.2313 24.9012 17.9963 25.1101 17.8134L27.2127 15.8414L28.14 14.9534L26.181 15.0317H12.7687Z' fill='%23007AFF'/%3E%3C/svg%3E%0A")  no-repeat  center / auto;
    background-size: contain;
}
.section__footer {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 1000px) {
    .section__footer {
        flex-direction: column;
        padding: 0 15px;

    }
}
.contact {
    display: flex;
    align-items: center;

    height: 29px;
    width: 100%;
    max-width: 486px;
    /*justify-content: flex-end;*/
}
@media screen and (max-width: 1000px) {
    .contact {
        justify-content: center;
    }
}
.store {
    padding-right: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.store > a{
    height: 44px;
    width: 190px;
}

@media screen and (max-width: 1000px) {
    .store {
        padding-right: 0;
        margin-bottom: 30px;
    }
}
.logo {
    height: 83px;
    width: 194px;
    margin-bottom: 4px;
}
@media screen and (min-width: 768px) {
    .logo {
        margin-bottom: 0;
    }
}
/* стили только к Import Page END */
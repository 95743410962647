.contact{
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 29px;
}

.contact__link{
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #797979;
    padding-left: 55px;
    display: block;
    position: relative;
}

.contact__link + .contact__link{
    margin-left: 30px;
}

.contact__link:before{
    content: '';
    width: 37px;
    height: 29px;

    background-repeat: no-repeat;
    background-position: left;
    background-size: auto;

    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.contact__link_mail:before{
    background-image: url("../icons/envelope.svg");
}

.contact__link_tw{
    padding-left: 45px;
}
.contact__link_tw:before{
    width: 35px;
    background-image: url("../icons/twitter.svg");
}

@media screen and (min-width: 768px){
    .contact{
        justify-content: center;
    }
    .contact__link{
        margin: 0 40px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

/* стили из main START */
.title{
    font-weight: 600;
    font-size: 40px;
    line-height: 36px;
    text-transform: uppercase;
    color: #373A42;
    font-family: 'Barlow Condensed', sans-serif;
}

.title_center{
    text-align: center;
}

.section__title{
    margin-bottom: 44px;
}

.section__columns{
    display: flex;
}

.section__p{
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    letter-spacing: 1px;
    color: #373A42;
    margin: 20px 0;
}

.section__p:first-child{
    margin-top: 35px;
}
/* стили из main END */

/* COMPONENT button START */
.button{
    width: 100%;
    height: 56px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 5px;

    border: 0;
    border-radius: 8px;
    outline: none;

    cursor: pointer;
    transition: 200ms;
}

.button:hover{
    background: #E5EFFA;
}
.button:active{
    background: #B8D4F0;
}

.button_gradient{
    background: linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.44);
}

.button_gradient:hover{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.64);
}

.button_gradient:active{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.54);
}

.button__text{
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 1.3px;
    color: #007AFF;
}

.button__text_white{
    color: #FFFFFF
}

.button__icon{
    width: 25px;
    height: 25px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    margin-right: 10px;
    margin-top: -4px;
    margin-left: 5px;
}

.button__icon_auth{
    background-image: url("../icons/ic_auth.svg");
}

.button_err:hover,
.button_err{
    background-color: #FF3B30;
}
.button_err .button__text{
    color: #fff;
}

/* COMPONENT button END */

/* стили только к Import Page START */
.drop_info{
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.drop_info{
    max-width: 426px;
    min-width: 400px;
}


@media screen and (max-width: 1000px){
    .drop_info{
        padding-left: 0px;
        max-width: 100%;
        min-width: auto;
    }
}


.progressbar{
    height: 4px;
    background-color: #34C759;
    margin-bottom: 20px;
}


.counter__num{
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    color: #34C759;
    margin-bottom: 25px;
}

.counter__description{
    font-weight: 600;
    font-size: 40px;
    line-height: 36px;
    display: flex;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-bottom: 40px;
}

.counter__description_uploaded{
    color: #34C759;
}

.counter__description > svg{
    position: relative;
    top: -2px
}

.counter__description_text{
    color: #34C759;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
}

.counter__description_err_outer{
    width:100%;
    display: flex;
    justify-content: center;
}

.counter__description_err{
    color: #FF6B7D;
    white-space: pre-line;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: 'Barlow Condensed', sans-serif;
    text-transform: uppercase;
    padding-left: 65px;
    background-image: url("../images/error-icon.png");
    background-repeat: no-repeat;
    background-position: top 10px left;
    position: relative;
    top: -10px;
}

.counter__description_err strong{
    font-weight: 700;
    font-size: 40px;
    display: block;
    margin-bottom: 16px;
    line-height: 1.2;
}


.counter__description_err span{
    font-weight: 500;
    font-size: 24px;
}



.counter__description_left{
    text-align: left;
}

.status_cancelled .counter__num{
    color: #FF9500;
}
.status_cancelled .counter__description span{
    color: #FF9500;
}

.status_cancelled .progressbar{
    background: #78788033;
    position: relative;
}


.status_cancelled .progressbar_done{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100%;
    background-color: #FF9500;
    animation-duration: 3s;
    animation-name: slidein;
    transition: all ease .3s;
}


.status_processed .button{
    width: 135px;
    height: 45px;
    color: #007AFF;
    text-align: center;

}

.status_processed .button span{
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: 400;
}


.status_processed .progressbar{
    background: #78788033;
    position: relative;
}


.status_processed .progressbar_done{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100%;
    background-color: #007AFF;
    animation-duration: 3s;
    animation-name: slidein;
    transition: all ease .3s;
}


.status_processed .counter__num{
    color: #007AFF;
}

.status_processed .counter__description{
    display: flex;
    justify-content: space-between;
}

.status_replay2{
    margin-top: 115px;
}

.load{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    letter-spacing: 1px;
    color: #373A42;

    margin: 40px 0;
}

.note{
    padding: 16px 24px;
    padding-left: 74px;

    background: rgba(237, 241, 245, 0.5) url("../icons/ic_warning_triangle.svg") no-repeat left 24px center / auto;
    border-radius: 20px;
    font-weight: 300;
    font-size: 19px;
    line-height: 23px;

    color: #373A42;
}

.load__button{
    width: 126px;
    margin: 0 auto;
    margin-top: 40px;
}

.warning{
    width: 30px;
    height: 30px;
    background: url("../icons/ic_warning_circle.svg") no-repeat center / auto;
}


.loader{
    border: 3px solid #32B1AA;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 1s loader linear infinite;
    position: relative;

    width: 20px;
    height: 20px;
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* стили только к Import Page END */
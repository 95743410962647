.contactsWrapper {
    /*width: 100%;*/
    padding: 50px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactBox + .contactBox {
    margin-left: 40px;
}

.contactBox a {
    margin-left: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #B6B6B6;
}

/*@media all and (max-width: 1100px) {*/
/*    .contactsWrapper {*/
/*        justify-content: space-between;*/
/*    }*/

/*    .contactBox + .contactBox {*/
/*        margin-left: 20px;*/
/*    }*/
/*}*/
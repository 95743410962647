/* стили из main START */
.page{
    /*background-color: #F2F2F7;*/
    padding: 24px 0;

    font-family: 'Museo Sans Cyrl';
    color: #373A42;
}

.wrap{
    max-width: 1092px;
    margin: 0 auto;
}

@media screen and (max-width: 1100px){
    .wrap{
        padding: 0 16px;
    }
}

.section{
    background: #FFFFFF;
    border-radius: 32px;

    max-width: 1092px;
    margin: 40px auto;
    padding: 65px 0;
}

.section__headline{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link{
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 1px;
    text-decoration: none;
    color: #007AFF;
    margin: 0 auto 0 22px;
    font-weight: 400;
}

.headline__link{
    display: none;
}

@media screen and (min-width: 768px){
    .headline__link{
        display: inline-block;
    }
    .section__headline{
        flex-direction: row;
        align-items: center;
    }
    .logo{
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1100px){
    .page{
        padding: 4px 0;
    }
    .section{
        margin: 32px auto;
        padding: 35px 0;
    }
    .section__wrap{
        padding: 0 16px;
        max-width: 664px;
    }
}
/* стили из main END */

/* COMPONENT links to appstore and play market START*/
.store{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* COMPONENT links to appstore and play market END*/

/* COMPONENT contact START */
.contact{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 29px;
}
/* COMPONENT contact END */

.note{
    padding: 16px 24px;
    padding-left: 74px;

    background: rgba(237, 241, 245, 0.5) url("../icons/ic_warning_triangle.svg") no-repeat left 24px center / auto;
    border-radius: 20px;
    font-weight: 300;
    font-size: 19px;
    line-height: 23px;

    color: #373A42;
}

/* только к invite START */
.store{
    margin-bottom: 48px;
    margin-top: 28px;
}
.section__wrap{
    max-width: 568px;
    margin: 0 auto;
}
.section_content{
    text-align: center;
}
.note{
    max-width: 434px;
    margin: 24px auto;
    text-align: left;
}

.section__p{
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 1px;
    color: #373A42;
    margin: 24px 0 32px;
}

.section__title{
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;

    color: #373A42;
}
.section__title span{
    opacity: .75;
    text-transform: none;
}

.user_img{
    border-radius: 0 7px 7px 7px;
    margin-bottom: 16px;
    width: 88px;
    height: 120px;
    object-fit: cover;
    display: inline-block;
}

.section_content__warn,
.section_content__private{
    margin-top: 64px;
}
.section_content__group{
    margin-top: 40px;
}

.section__p__desktop{
    display: none;
}

.emj,
.section__p__mobile{
    display: block;
    margin: 12px auto;
}


@media screen and (min-width: 768px){
    .store{
        margin-bottom: 64px;
    }

    .section__p__mobile{
        display: none;
    }

    .section__p__desktop{
        display: block;
    }
}
/* только к invite END */
.main {
    padding: 50px 0 0px;
    display: flex;
    flex-wrap: wrap;
}

.content {
    width: 65%;
}

.image {
    width: 35%;
}

.image img {
    max-height: 590px;
    max-width: 100%;
    object-fit: contain;
}

.contactUs {
    max-width: 350px;
    margin: 0 auto;
    padding: 0 15px;
}

.successFeedbackWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.successFeedbackBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 492px;
    padding: 24px;

    background: rgba(255, 255, 255, 0.06);
    border-radius: 20px;
}

.successFeedbackTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    height: 36px;

    font-family: 'Museo Sans Cyrl';
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height */

    color: #32B1AA;

    text-align: center;
}

.successFeedbackTitleIcon {
    width: 30px;
    height: 30px;
}

.successFeedbackMessage {
    margin-top: 16px;
    height: 60px;

    font-family: 'Museo Sans Cyrl';
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;

    color: #7F86B4;
}

.error {
    width: 100%;
    height: 36px;
    background: #FF3B30;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -16px;
    margin-bottom: 16px;
}

.errorIcon {
    margin-left: 10px;
    margin-right: 10px;
    width: 21px;
    height: 21px;
    display: flex;
}

.errorText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
}

.title {
    font-weight: 900;
    font-size: 27px;
    line-height: 32px;
    padding-bottom: 40px;
    text-transform: uppercase;
    background: linear-gradient(111.72deg, #FFD600 0.84%, #FF005C 90.64%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.mobiles {
    padding-left: 40px;
    padding-bottom: 56px;
    display: flex;
}

.login {
    padding-left: 40px;
    padding-bottom: 72px;
}

.loginButton {
    height: 52px;
    padding: 0 54px;
    display: flex;
    align-items: center;
    background: linear-gradient(111.72deg, #00F0FF 0.84%, #FA00FF 90.64%);
    border-radius: 58px;
    cursor: pointer;
    text-decoration: none;
    border: none;
    outline: none;
}

.contactUsHeader {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 46px;
}

.contactUsForm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.email_label {
  font-family: 'Museo Sans Cyrl', sans-serif;
  font-size: 11px;
  font-weight: 300;
  margin-left: 56%;
  margin-right: 0;
  position: relative;
  top: -15px;
}

.input, .textarea {
    font-family: 'SF Pro Text', sans-serif;
    width: calc((100% - 20px) / 2);
    margin-bottom: 20px;
    height: 44px;
    padding: 12px 18px;
    font-size: 17px;
    line-height: 16px;
    font-weight: 100;
    color: #fff;
    background-color: #1C1C1E;
    border-radius: 12px;
    border: none;
    outline: none;
}

.input::placeholder, .textarea::placeholder {
    color: rgba(235, 235, 245, 0.3);
}

.input + .input {
    margin-left: 20px;
}

.input{
    box-sizing: border-box;
}

.textarea {
    width: 100%;
    height: 148px;
    resize: none;
    box-sizing: border-box;
}

.captcha {
    width: 300px;
    margin: 0 auto;
    padding-bottom: 40px;
}

.send {
    display: flex;
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 12px;
    background-color: #007AFF;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.send[data-disabled=true] {
    color: currentColor;
    cursor: not-allowed;
    opacity: .5;
    text-decoration: none;
}

.modal {
    background: rgba(255, 255, 255, 0);
    padding: 100px 0 0 0;
    display: flex;
    justify-content: center;
}

.terms {
    padding-top: 25px;
    text-align: center;
}

@media all and (max-width: 1100px) {
    .main {
        padding: 40px 0 0 0;
        margin-top: 30px;
    }

    .content {
        width: 100%;
        order: 1;
    }

    .image {
        width: 100%;
        order: 0;
        margin-bottom: 48px;
    }

    .contactUs {
        order: 2;
    }

    .successFeedbackWrapper {
        width: 100%;
        padding: 16px;
        order: 3;
    }

    .successFeedbackTitle {
        font-size: 18px;
        line-height: 22px;
    }

    .successFeedbackMessage {
        font-size: 16px;
        line-height: 19px;
    }

    .title {
        font-size: 18px;
        padding-bottom: 16px;
    }

    .mobiles {
        padding: 0 0 40px 0;
    }

    .login {
        padding: 0 0 56px 0;
        display: flex;
        justify-content: center;
    }

    .modal {
        padding: 100px 16px 0;
    }
}
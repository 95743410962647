@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.page{
    background-color: #F2F2F7;
    padding: 24px 0;

    font-family: 'Roboto';
    color: #373A42;
}

.wrap{
    max-width: 1092px;
    margin: 0 auto;
}

@media screen and (max-width: 1100px){
    .wrap{
        padding: 0 16px;
    }
}

.section{
    background: #FFFFFF;
    border-radius: 32px;

    max-width: 1092px;
    margin: 40px auto;
    padding: 65px 0;
}

.section__wrap{
    max-width: 632px;
    margin: 0 auto;
}

/* Title START */
.title{
    font-weight: 600;
    font-size: 44px;
    line-height: 44px;
    text-transform: uppercase;
    color: #373A42;
}

@media screen and (max-width: 1023px){
    .title{
        font-size: 32px;
        line-height: 32px;
    }
}





.title_center{
    text-align: center;
}

.section__title{
    margin-bottom: 44px;
    font-family: 'Barlow Condensed', sans-serif;
}

/* Title END */

@media screen and (max-width: 1100px){
    .page{
        padding: 4px 0;
    }
    .section{
        margin: 32px auto;
        padding: 35px 0;
    }
    .section__wrap{
        padding: 0 16px;
        max-width: 664px;
    }
    .section__title{
        margin-bottom: 32px;
    }
}

.headline{
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;
    gap: 20px;

}

.logo{
    height: 83px;
    width: 194px;
    margin-bottom: 4px;
}

.link{
    text-decoration: none;
    color: #007AFF;
}

.headline__link{
    display: none;
}

.headline__text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 1px;
    color: #000000;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 4px 30px;
    gap: 10px;

    width: 204px;
    height: 63px;
}

@media screen and (min-width: 768px){
    .headline__link{
        display: inline-block;
    }
    .logo{
        margin-bottom: 0;
    }
}

.liner{
    margin-top: 52px;
    display: none;
}

.liner__text{
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 1.3px;
    font-weight: 400;
}

.liner__text2{
    width: 480px;
    height: 52px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */
    text-align: center;
    letter-spacing: 1px;

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.liner__button{
    width: 432px;
    margin: 40px auto 0;
}

@media screen and (min-width: 768px){
    .liner{
        display: block;
    }

    .whytapyou{
        display: none;
    }
}

@media screen and (max-width: 768px){
    .section:first-child {
        background: none;
    }
}

/* button START */
.button{
    width: 100%;
    height: 56px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 8px;
    outline: none;

    cursor: pointer;
    transition: 200ms;
    background: #EDF1F5;
    font-family: 'Roboto';
}

.button:hover{
    background: #E5EFFA;
}
.button:active{
    background: #B8D4F0;
}

.button_gradient{
    background: linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.44);
}

.button_gradient:hover{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.64);
}

.button_gradient:active{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.54);;
}

.button__text{
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 1.3px;
    color: #007AFF;
}

.button__text_white{
    color: #FFFFFF
}

.button__icon{
    width: 25px;
    height: 25px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    margin-right: 10px;
    margin-top: -4px;
    margin-left: 5px;
}

.button__icon_auth{
    background-image: url("../icons/ic_auth.svg");
}
/* button END */

.form__button{
    width: 184px;
    margin: 44px auto 0;

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
}

button[data-disabled=true] {
    color: currentColor;
    cursor: not-allowed;
    opacity: .5;
    text-decoration: none;
}

/* input START */
.input{
    background: #EDF1F6;
    backdrop-filter: blur(20px);

    border: 2px solid transparent;
    outline: none;
    border-radius: 8px;

    padding: 0 16px;

    width: 100%;
    height: 44px;

    font-family: 'Roboto';
    font-size: 19px;
    line-height: 23px;
    color: #414141;
    transition: 200ms;
}

.input_error{
    border-color: #F44C4C;
}

.input_center{
    text-align: center;
}

.input_number{
    width: 55px;
    height: 52px;
    font-weight: 900;
    font-size: 20px;
    line-height: 100%;
    color: #373A42;
    margin: 0 8px;
}



.textarea:hover,
.textarea:focus,
.input:hover,
.input:focus{
    background: #EDF1F6
}

.textarea::placeholder,
.input::placeholder {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    color: #797979;
}

.textarea{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;

    color: #414141;
    background: #EDF1F6;
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 8px 16px;

    border: 0;
    outline: none;
    resize: none;

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;

    width: 100%;
    height: 147px;

    transition: 200ms;
}
/* input END */

.modal{
    width: 100vw;
    height: 100vh;
    padding: 16px;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(27, 27, 37, 0.15);

    overflow-y: auto;
}

/* */
.modal__warning{
    background: #FF3B30 url("../icons/ic_warning.svg") no-repeat left 8px center / 21px;
    border-radius: 8px;
    padding: 11px 8px 9px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;

    color: #FFFFFF;
    margin-bottom: 16px;
}
.modal__warning_text{
    margin-left: 30px;
}

/* COMPONENT links to appstore and play market START*/
.store{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* COMPONENT contact START */
.contact{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* */

.form{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;

    margin-bottom: 100px;
}

.form__captcha {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    margin: auto;
}

.terms {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 5;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.terms > a {
    color: #373A42;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.page{
    background-color: #F2F2F7;
    /*padding: 24px 0;*/

    font-family: 'Roboto';
    color: #373A42;
}

.wrap{
    /*max-width: 1092px;*/
    margin: 0 auto;
}

.section{
    background: #FFFFFF;

    max-width: 692px;
    margin: 0px auto;
    padding: 20px 0;
}

.section__wrap{
    max-width: 632px;
    margin: 0 auto;
}

.title{
    font-weight: 600;
    font-size: 44px;
    line-height: 44px;
    text-transform: uppercase;
    color: #373A42;
}

.title_center{
    text-align: center;
}

.section__title{
    /*margin-bottom: 44px;*/
    font-family: 'Barlow Condensed', sans-serif;
}

.success__section{
    height: 506.6px;
}

.liner__text{
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 1.3px;
    font-weight: 400;
}

/* button START */
.button{
    width: 100%;
    height: 56px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 8px;
    outline: none;

    cursor: pointer;
    transition: 200ms;
    background: #EDF1F5;
    font-family: 'Roboto';
}

.button:hover{
    background: #E5EFFA;
}
.button:active{
    background: #B8D4F0;
}

.button__text{
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 1.3px;
    color: #007AFF;
}

/* button END */

.form__button{
    width: 184px;
    margin: 44px auto 0;

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
}

button[data-disabled=true] {
    color: currentColor;
    cursor: not-allowed;
    opacity: .5;
    text-decoration: none;
}

/* input START */
.input{
    background: #EDF1F6;
    backdrop-filter: blur(20px);

    border: 2px solid transparent;
    outline: none;
    border-radius: 8px;

    padding: 0 16px;

    width: 100%;
    height: 44px;

    font-family: 'Roboto';
    font-size: 19px;
    line-height: 23px;
    color: #414141;
    transition: 200ms;
}

.input_error{
    border-color: #F44C4C;
}

.input_center{
    text-align: center;
}

.input_number{
    width: 55px;
    height: 52px;
    font-weight: 900;
    font-size: 20px;
    line-height: 100%;
    color: #373A42;
    margin: 0 8px;
}



.textarea:hover,
.textarea:focus,
.input:hover,
.input:focus{
    background: #EDF1F6
}

.textarea::placeholder,
.input::placeholder {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    color: #797979;
}

.textarea{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;

    color: #414141;
    background: #EDF1F6;
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 8px 16px;

    border: 0;
    outline: none;
    resize: none;

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;

    width: 100%;
    height: 147px;

    transition: 200ms;
}
/* input END */

.modal{
    width: 100vw;
    height: 100vh;
    padding: 16px;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(27, 27, 37, 0.15);

    overflow-y: auto;
}

/* */
.modal__warning{
    background: #FF3B30 url("../icons/ic_warning.svg") no-repeat left 8px center / 21px;
    border-radius: 8px;
    padding: 11px 8px 9px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;

    color: #FFFFFF;
    margin-bottom: 16px;
}
.modal__warning_text{
    margin-left: 30px;
}

/* */

.form{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.form__captcha {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    margin: auto;
}

.terms {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 5;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.terms > a {
    color: #373A42;
}